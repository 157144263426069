import React, {Component} from "react";
import { flushSync } from 'react-dom';

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import LoadingAdd from "./loading_add.tsx";
import LoadingsPrint from "./loadings_print.tsx";
import LoadingsFinalCalculation from "./loadings_final_calculation.tsx";
import ExpenseAdd from "../expenses/expense_add.tsx";
import Audit from "../common/audit";
import Address from "../common/address";
import CompanyCard from "../companycard";
import LoadingCard from "../loadingcard";
import ManufacturingCard from "../manufacturingcard";
import SpeditionCard from "../speditioncard";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import ActionsRenderer from "../../aggrid/ActionsRenderer.jsx";
import MainTableColumnDefs from "../../aggrid/MainTableColumnDefs.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import DatePicker from "../../aggrid/DatePicker.js";
import DateTimePicker from "../../aggrid/DateTimePicker.jsx";
import loadingsStatusCount from "../../aggrid/loadingsStatusCount.jsx";

import MultiSelectSimple from "../../aggrid/MultiSelectSimple.jsx";
import ManufacturingRenderer from "../../aggrid/ManufacturingRenderer.jsx";
import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData.jsx";

import InvoiceAdd from "../invoices/invoice_add.tsx";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Pusher from 'react-pusher';
import {LoadState, SaveState} from "../../services/GridStateService";

import AgGridState from "../common/ag_grid_state";


class Loadings extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
		
        const customComparator = (valueA, valueB) => {
			
			if( valueA == null && valueB == null ){
				
				return 0;
				
			}
			
			if( valueA == null ){
				
				return -1;
				
			}
			
			if( valueB == null ){
				
				return 1;
				
			}
			
			if( ( typeof( valueA ) !== 'string' ) || ( typeof( valueB ) !== 'string' ) ) {

               if( valueA > valueB ) {

					return 1;
					
				} else if( valueA < valueB ) {

					return -1;
					
				} else {
					
					return 0;
					
				}
			   
			}
			
			return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
			
            /*if ( valueA && valueA != null && valueB && valueB != null && typeof( valueA.toLowerCase ) == 'function' && typeof( valueB.toLowerCase ) == 'function')
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());*/
        };


        super(props)
        
        this.AuditElement = React.createRef();
		
		let columnDefinitions = MainTableColumnDefs('loadings', this.showLoadingCard.bind(this), this.props.opts, this.showManufacturingCard.bind(this));
		
		if( this.props.fromSpedition === true ){
			
		
			columnDefinitions.push({
				
				field: "show_in_speditions",
				headerName: "Show in speditions",
				valueGetter: function (params) {
					
					if( params.node && params.node.childrenAfterFilter &&  params.node.childrenAfterFilter[ 0 ] ){
						
						return params.node.childrenAfterFilter[ 0 ].data[ "show_in_speditions" ];
						
					} else {
					
						return params.data["show_in_speditions"];
						
					}
					
				},
				valueSetter: null,
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: true

				
			});
			
			for( let i in columnDefinitions ){
				
				if( columnDefinitions[ i ].field == 'odvoz' ){
					
					columnDefinitions[ i ].hide = true;
					
					// console.log( "hidden_odvoz" );
					
				}
				
			}
		
		}
		
		columnDefinitions.push({
				
			field: "is_loading_stored",
			headerName: "Is loading stored",
			type: 'string',
			valueGetter: function ( params ) {
				
				if( params.node && params.node.childrenAfterFilter &&  params.node.childrenAfterFilter[ 0 ] ){
					
					return params.node.childrenAfterFilter[ 0 ].data[ "is_loading_stored" ];
					
				} else {
				
					return params.data[ "is_loading_stored" ];
					
				}
				
			},
			valueSetter: null,
			comparator: function (valueA, valueB) {
				
				return valueA == valueB;
				
			},
			hide: true

			
		});
		
		let TransportationHandler = function( loading ){
					
			if( 
				( loading.invoice_type == null ) &&
				( loading.invoice_incvat_kc_each == null )
			){
		
				let incvat = loading.invoice_incvat ? parseFloat( loading.invoice_incvat ) : 0;
				
				return 0;
			
			} else {
				
				let incvat = 0; //сумма за лоадинг от суммы всех инвоисов
				
				let transportation_incvat = 0; //сумма за доправу от суммы всех инвоисов
				
				
				let invoices_loadings = !loading.invoice_loadings ? [ ] : loading.invoice_loadings.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //погрузки каждого инвойса текущей погрузки
				
				let invoices_types = loading.invoice_type.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //тип каждого инвойса текущей погрузки
			
				let invoices_incvat_kc_each = loading.invoice_incvat_kc_each.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //сумма каждого инвойса текущей погрузки
				
				
				if(
					( invoices_types.length !== invoices_incvat_kc_each.length ) 
				){
				
					throw new Error( "Critical error" );
				
				}
				
				for(
					let Iterator = 0;
					Iterator < invoices_types.length; //проходим каждый инвойс
					Iterator++
				){
					
					let invoice_loadings = !invoices_loadings[Iterator] ? [] : invoices_loadings[Iterator].split("//").map(function (el) { return el.trim(); }).filter(function (el) { return el != ''; }); //лоадинги инвоиса
					
					console.log({ invoice_loadings });
				
					let invoice_loadings_weight = 0; //вес всех лоадингов инвойса
					
					if( invoice_loadings.length > 0 ){
				
						for(
							let Iterator2 = 0;
							Iterator2 < invoice_loadings.length;//проходим каждый лоадинг инвойса
							Iterator2++
						){
							
							let invoice_loading = this.state.loadingsObjectByLoadingNumber[ invoice_loadings[ Iterator2 ] ];
							
							console.log( invoice_loading );
							
							if( invoice_loading && invoice_loading.net_material_qty !== null ){
							
								invoice_loadings_weight = invoice_loadings_weight + parseInt( invoice_loading.net_material_qty );
						
							}
							
						}
					
					} else {
						
						console.log( loading );
						
						if( loading.net_material_qty !== null ){
						
							invoice_loadings_weight = parseInt(loading.net_material_qty);	

						}
					}
					
					if( invoice_loadings_weight === 0 ){
						
						continue;
						
					}
					
				
					let invoice_amount = ( invoices_incvat_kc_each[ Iterator ] ? parseFloat( invoices_incvat_kc_each[ Iterator ] ) : 0 );
					
					let invoice_amount_per_unit = invoice_amount / invoice_loadings_weight; //сумма за кг(т) в инвоисе
					
					console.log( invoice_amount, invoice_amount_per_unit );
					
					let invoice_loading_amount = 0;
					
					
					if( loading.net_material_qty !== null ){
					
						invoice_loading_amount = parseInt( loading.net_material_qty ) * invoice_amount_per_unit; //сумма за лоадинг от суммы инвоиса
					
					}
					
				
					if( invoices_types[ Iterator ] != "2" ){
					
						incvat = incvat + invoice_loading_amount;

					} else {
					
						transportation_incvat = transportation_incvat + invoice_loading_amount;
					
					}
					
					
				
				}
				
				return +transportation_incvat.toFixed(2);
				
			}
			
		}.bind( this );
		
		columnDefinitions.push( {
				
			field: "transportation",
			headerName: "Doprava Kč",
			type: 'string',
			valueGetter: function ( params ) {
				
				// console.log( "Doprava", params.node );
				
				if( params.data && params.data.id ) {
					
					let loading = params.data;
					
					return TransportationHandler( loading );
					
				} else {

					let Children = params.node.allLeafChildren;
					
					let Total = 0;
					
					for( 
						let Iterator = 0;
						Iterator < Children.length;
						Iterator++
					){
						
						let ChildData = Children[ Iterator ].data;
						
						console.log( ChildData );
						
						Total = Total + TransportationHandler( ChildData );
						
						//Total = Total + ChildData.transportation;
						
					}
					
					return Total;
					
				}
				
			}.bind( this ),
			valueSetter: null,
			comparator: function (valueA, valueB) {
				
				if( valueA == null && valueB == null ){
				
					return 0;
					
				}
				
				if( valueA == null ){
					
					return -1;
					
				}
				
				if( valueB == null ){
					
					return 1;
					
				}
				
				

				if( valueA > valueB ) {

					return 1;
					
				} else if( valueA < valueB ) {

					return -1;
					
				} else {
					
					return 0;
					
				}
					
				
			},
			editable: false,
			hide: false

			
		} );
		
		// console.log( columnDefinitions );
		
		
		let my_user = JSON.parse( localStorage.my_user );
		
		let isAdmin = false;
		
		for(
			let i in my_user.roles
		){
			
			if( my_user.roles[ i ].id == 1 ){
				
				isAdmin = true;
				
				break;
				
			}
					
		}
		
        this.state = {
			locale: locale,
            isColumnStateLoaded: false,

            showLoading: true,
            rowSelected: false,
            pages: 0,
            popupParent: document.querySelector('body'),
            showLoadingCard: false,
            showLoadingCardName: this.props.t('Loading number'),
            showLoadingCardId: null,
            showLoadingCardTab: null,

            showManufacturingCard: false,
            showManufacturingCardName: this.props.t('Loading number'),
            showManufacturingCardId: null,
            showManufacturingCardNode: null,

            showInvoiceAdd: false,
            showInvoiceAddRownodes: [],
			
			showLoadingsPrint: false,
			
			isAdmin: isAdmin,
			
			showExpensesAdd: false,
			isDisabledExpenseAddButton: true,
			expense_manager: null,
			loadingsSelected: null,
			
			loadingsObject: {},
			loadingsObjectByLoadingNumber: {},
			
			showFinalCalculation: false,
			isDisabledFinalCalculationButton: true,
			final_calculation_manager: null,
			final_calculation_top_manager: null,
			final_calculation_loadings: [ ],
			loadingsArrayStamp: ( new Date( ) ).getTime( ),

            showLoadingCardTypeLoading: null,
            showModalSpedition: false,
            showSpeditionLoadings: [],
            showModal: false,
            showModalSMS: false,
            showModalEmail: false,
            showModalSMSAddresses: [],
            showModalEmailAddresses: [],
            showArchive: 'Show actual',//showArchive: 'Show archive',
            currentArchive: 'Show all',//currentArchive: 'Show actual',
            showAudit: false,
            showAuditId: null,
            showContact: false,
            showContactNode: null,
            showContactId: null,
            showCompanyCard: false,
            showCompanyCardName: 'Company card',
            showCompanyCardNode: null,
            showCompanyCardId: null,
            showAddress: false,
            showAddressName: 'Address',
            showAddressNode: null,
            showAddressId: null,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: columnDefinitions,


            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'loadingsStatusCount',
                        align: 'left',
                        key: 'statusCountKey'
                    },

                ],
            },

            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

                comparator: customComparator
            },

            frameworkComponents: {

                DeleteRenderer: DeleteRenderer,
                ActionsRenderer: ActionsRenderer,
                FileRenderer: FileRenderer,
                MultiSelectSimple: MultiSelectSimple,
                DateTimePicker: DateTimePicker,
                MultiSelectLargeData: MultiSelectLargeData,
                ManufacturingRenderer: ManufacturingRenderer,
                loadingsStatusCount: loadingsStatusCount,
                DatePicker: DatePicker,
            },

			AgGridReady: false
        };


    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            var columnSettingsName = 'LoadingsColumns';
            if (this.props.opts && this.props.opts.type === 'storage') columnSettingsName = 'StorageColumns';
            if (this.props.opts && this.props.opts.type === 'galvanisation') columnSettingsName = 'GalvanisationColumns';
            //SaveState(columnSettingsName, params.columnApi, params.api);
        }
    }

	onDataReceived( ){
		
		this.setState( {
							
			currentArchive: 'Show all',//currentArchive: 'Show actual',
			showArchive: 'Show actual'//showArchive: 'Show archive'
							
		}, ( ) => this.setFilter( ) );
		
		this.gridApi.redrawRows( );


		const statusBarComponent = this.gridApi.getStatusPanel( 'statusCountKey' );
		
		let componentInstance = statusBarComponent;
		
		if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
			
			componentInstance = statusBarComponent.getFrameworkComponentInstance( );
			
		}

		if ( componentInstance ) {
			
			componentInstance.forceUpdate( );
			
		}
		
		this.gridApi.onFilterChanged( );
		
		
		
		let columnSettingsName = null;
		
		if (this.props.opts && this.props.opts.type === 'storage'){ 
			
			columnSettingsName = 'StorageColumns';
			
		} else if( this.props.opts && this.props.opts.type === 'galvanisation' ) {
			
			columnSettingsName = 'GalvanisationColumns';
			
		} else {
			
			columnSettingsName = 'LoadingsColumns';
			
		}
		
		//LoadState( columnSettingsName, this.gridColumnApi, this.gridApi );
		
		this.setState( {
			isColumnStateLoaded: true
		} );
		
	}

    componentDidMount( ) {
		
        let url = UrlService.apiDomain( );
		
        if( this.props.opts && this.props.opts.type === 'storage' ) {
			
			url = url + 'api/loadings/storage';
        
		} else if (this.props.opts && this.props.opts.type === 'galvanisation') {
			
			url = url + 'api/loadings/galvanisation';
        
		} else {
			
			url = url + 'api/loadings/' + this.props.id;
			
		}
		
        if (this.props.id !== null) {
			
			HttpService.get( 
				url 
			).then( function( res ) {
				
				for(
					let i in res.data
				){
					
					if( res.data[ i ].loading_stored == null ){
						
						res.data[ i ].is_loading_stored = "false"
						
					} else {
					
						res.data[ i ].is_loading_stored = "true";
					
					}
					
				}
				
				const loadingsObject = { };
				
				const loadingsObjectByLoadingNumber = { };

				res.data.map( function( loading ){
					
					loadingsObject[ loading.id ] = loading;
					
					loadingsObjectByLoadingNumber[ loading.loading_number ] = loading; 
					
				} );
				
				this.setState( {
					rowdata: res.data,
					loadingsObject: loadingsObject,
					loadingsObjectByLoadingNumber: loadingsObjectByLoadingNumber
				} );

                setTimeout(
					this.onDataReceived.bind( this ), 
					10
				);

            }.bind( this ) ).catch( function( error ){
				
                console.log( error );
            
			}.bind( this ) );
			
		}

    }

    componentDidUpdate(prevProps) {
		
		// console.log( "loading update", this.props );

        if( prevProps.tmp !== this.props.tmp ){


            if (this.props.id != null) {
                this.setState({
                    rowdata: null
                });

                this.componentDidMount();


            }

            this.setState(
				{
					showArchive: 'Show actual'//showArchive: 'Show archive'
				}, 
				() => this.setFilter( ) 
			);

        }
		
		if( 
			( prevProps.year != this.props.year ) || 
			( prevProps.month != this.props.month ) 
		){
			
			this.setFilter( );
			
		}
		
		if( 
			( prevProps.continent != this.props.continent ) || 
			( prevProps.country != this.props.country ) 
		){
			
			this.setFilter( );
			
		}
		
    }

	setFilter( ){
		
		// console.log( "set filter", this.state.currentArchive, this.gridApi, this.props );
		
		let filtr = {
			
		};
		
		if( this.props.continent != null && this.props.country != null ){
			
			let countries = JSON.parse( localStorage.getItem( 'countries' ) );
			
			let country = null;
			
			for( let i in countries ){
				
				if( countries[ i ].id.toString( ) == this.props.country.toString( ) ){
					
					country = countries[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}

			filtr = {
				...filtr,
				...{
					country: {
						//filterType: 'number',
						//type: 'equals',
						//filter: this.props.country.toString( )
						type: 'set',
						values: [ country ]
					}
				}
			};
				
		} else {
			
		}
		
		
		if ( this.props.opts && ( this.props.opts.type === 'storage' || this.props.opts.type === 'galvanisation' ) ) {
			
			if (this.state.currentArchive === 'Show archive') {
				
				filtr = { 
					...filtr, 
					...{ 
						remaining_qty: {
							filterType: 'number',
							type: 'lessThanOrEqual',
							filter: 0
						}
					}
				};

			}

			if (this.state.currentArchive === 'Show all') {

			}

			if (this.state.currentArchive === 'Show actual') {
				
				
				filtr = { 
					...filtr, 
					...{
						remaining_qty: {
							filterType: 'number',
							type: 'greaterThan',
							filter: 0
						}
					} 
				};
				
			}
			
			if( this.props.year != null && this.props.month != null ){

				filtr = {
					...filtr, 
					...this.dateFilter( )
				};

			}
			
			this.gridApi.setFilterModel( filtr );
			
		} else {
		
			if (this.state.currentArchive === 'Show archive') {

				filtr = {
					...filtr, 
					...{
						is_loading_stored: {
							type: 'set',
							values: [ "true" ]
						}
					},
					...{
						loading_stored: {
							filterType: 'date',
							type: 'inRange',
							dateFrom: '2020-01-01 00:00:01',
							dateTo: '2040-01-01 00:00:01',
						}
					}
				}
				
				if( this.props.year != null && this.props.month != null ){

					filtr = {
						...filtr, 
						...this.dateFilter( false )
					};

				}
				
				if( this.props.fromSpedition === true ){

					filtr = {
						...filtr,
						...{
							show_in_speditions: {
								type: 'set',
								values: [ "true" ]
							}
						}
					};

				}

				// console.log( "filtr", filtr );

				this.gridApi.setFilterModel( filtr );
				
				if( this.props.fromSpedition === true ){
				
					const sortModel = [
						{colId: 'transport_starting_from', sort: 'desc'}
					];
					
					this.gridApi.setSortModel( sortModel );
					
				}
				
			}

			if (this.state.currentArchive === 'Show all') {
				
				if( this.props.fromSpedition === true ){
					
					filtr = {
						...filtr,
						...{
							show_in_speditions: {
								type: 'set',
								values: [ "true" ]
							}
						}
					};
					
					// console.log( "filtr", filtr );

					this.gridApi.setFilterModel( filtr );

				} else {
				
					filtr = {
						...filtr,
						...{
							is_loading_stored: {
								type: 'set',
								values: [ "true", "false" ]
							}
						}
						
					};
					
					
					
					if( this.props.year != null && this.props.month != null ){

						filtr = {
							...filtr,
							...this.dateFilter( true )
						};

					}
					
					// console.log( "filtr", filtr );
					
					this.gridApi.setFilterModel( filtr );
				
				}
				
				if( this.props.fromSpedition === true ){
				
					const sortModel = [
						{colId: 'transport_starting_from', sort: 'desc'}
					];
					
					this.gridApi.setSortModel( sortModel );
					
				}
				
			}

			if (this.state.currentArchive === 'Show actual') {
				
				filtr = {
					...filtr,
					...{
						is_loading_stored: {
							type: 'set',
							values: [ "false" ]
						}
						/*loading_stored: {
							filterType: 'date',
							type: 'equals',
							dateFrom: '1999-01-01 00:00:01',
							dateTo: null
						}*/
					}
				};
				
				
				if( this.props.fromSpedition === true ){
						
					filtr = {
						...filtr,
						...{
							loading_stored: null
						}
					};
					
				}
				
				if( ( this.props.year != null ) && ( this.props.month != null ) ){

					//filtr = {
					//	...filtr,
					//	...this.dateFilter()
					//};

				}
				
				if( this.props.fromSpedition === true ){

					filtr = {
						...filtr,
						...{
							show_in_speditions: {
								type: 'set',
								values: [ "true" ]
							}
						}
					};

				}
				
				// console.log( "filtr", filtr );
				
				
				this.gridApi.setFilterModel(filtr);
				
				if( this.props.fromSpedition === true ){
				
					const sortModel = [
						{colId: 'transport_starting_from', sort: 'desc'}
					];
					
					this.gridApi.setSortModel( sortModel );
					
				}
										
			}
			
		}
		
    }

    onGridReady = (params) => {
		
        this.gridApi = params.api;
		
        this.gridColumnApi = params.columnApi;
	

        this.gridApi.setDomLayout('normal');

        var hidden = JSON.parse(localStorage.getItem('loadings_hiddencols'));

        this.gridColumnApi.setColumnsVisible(hidden, false);
		
		this.setState( {
			AgGridReady: true
		} );
		
    };

    onCellCValueChanged = (event) => {


        var group_data
        if (!event.data.id) {
            var newvalue = event.node.data.groupedvalue;
            var field = event.node.data.groupedfield;

            event.data = event.node.allLeafChildren[0].data;

            event.data[field] = newvalue;

        }
        event.data.field = event.colDef.field;

        HttpService.put(UrlService.apiDomain() + 'api/loadings/' + event.data.id, event.data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.alert !== '') alert(res.data.alert);
                if (res.data.result == 'failed') {
                    alert(res.data.message);

                } else {
                    // console.log('Successfully updated');
                    var rownode;
                    res.data.rows.map((e) => {
                        rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });

                    this.gridApi.refreshClientSideRowModel('aggregate')
                    this.gridApi.redrawRows({rowNodes: [rownode.parent]});

                }
            })
            .catch((error) => {
                alert('vv' + error);
                console.log(error);
            })


    }


    onCellClicked = (e) => {
        var that = this;
        var field = e.column.colId;

        if (field === "company") {
            if (e.node.data) {
                that.setState({
                    showCompanyCard: true,
                    showCompanyCardName: e.node.data.company,
                    showCompanyCardId: e.node.data.company_id,
                    showCompanyCardNode: null
                });
            } else {
                // console.log(e); return;
                e.node.data = e.node.allLeafChildren[0].data;
                that.setState({
                    showCompanyCard: true,
                    showCompanyCardName: e.node.data.company,
                    showCompanyCardId: e.node.data.company_id,
                    showCompanyCardNode: null
                });
            }


        }

    }


    getContextMenuItems = (params) => {

        var that = this;
        var result = [


            'copy',

            'paste',
            'export',


        ];

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('loadings.audit')) {
            result.push('separator');
            result.push({
                name: 'Audit',
                checked: true,
                action: function () {

                    if (params.node && params.node.data) {
                        that.setState({
                            showAudit: true,
                            showAuditId: params.node.data.id
                        });
                    }

                },
                icon:
                    '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
            })
        }
        return result;
    };

    getRowNodeId = (data) => {
        return data.id;
    };


    async handleAdd(data) {

        HttpService.post(UrlService.apiDomain() + 'api/loadings', data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    // console.log('Successfully updated');
                    this.setState({showModal: false});


                    var res = this.gridApi.applyTransaction({
                        add: res.data.loadings,
                        addIndex: 0,
                    });

                    this.gridApi.flashCells({rowNodes: res.add});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }
	
	async handlePrint(data) {
		
	}

    async addProduction(data) {
        if (data.no_salary != 1) data.no_salary = 0;
        var form_data = new FormData();

        for (var key in data) {
            if (key !== 'files' && key !== 'materials') form_data.append(key, data[key]);
        }

        form_data.append('materials', JSON.stringify(data.materials));

        if (typeof data.files[0] !== 'undefined') form_data.append('file1', data.files[0]);

        HttpService.post(UrlService.apiDomain() + 'api/production', form_data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    // console.log('Successfully updated');
                    this.setState({showManufacturingCard: false});

                    var rownode;
                    res.data.loadings.map((e) => {
                        rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }


    async acceptRoute(data) {

        HttpService.post(UrlService.apiDomain() + 'api/trips', data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    // console.log('Successfully updated');
                    this.setState({showModalSpedition: false});


                    var rowNodes = [];
                    res.data.loadings.forEach((e) => {
                        var rownode = this.gridApi.getRowNode(e.id);
                        rowNodes.push(rownode)
                        rownode.setData(e);
                    });

                    this.gridApi.flashCells({rowNodes: rowNodes});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }


    async deleteRows() {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {


                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/loadings/' + e.data.id)
                                .then(res => {
                                    // console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    showMultiPrint() {
        var mult_lns = [];
        this.gridApi.getSelectedNodes().forEach((e) => {
            // console.log(e);
            if (e.allLeafChildren && e.allLeafChildren[0]) {
                mult_lns.push(e.allLeafChildren[0].data.loading_number);
            }
        });

        this.setState({
            showLoadingCard: true,
            showLoadingCardTypeLoading: 'multiple',

            showLoadingCardName: this.props.t('Loadings'),
            showLoadingCardId: mult_lns,
        });
    }

    showLoadingCard(e, tab) {
        this.setState({
            showLoadingCard: true,
            showLoadingCardTypeLoading: 'single',
            showLoadingCardTab: tab,

            showLoadingCardName: this.props.t('Loading number') + ' ' + e.loading_number,
            showLoadingCardId: e.loading_number,
        })

    }

    showManufacturingCard(e) {

        var showManufacturingCardNode = 'manufacturing';
        if (this.props.opts && (this.props.opts.type === 'galvanisation')) showManufacturingCardNode = 'galvanisation';
        this.setState({
            showManufacturingCard: true,
            showManufacturingCardName: this.props.t('Loading number') + ' ' + e.loading_number,
            showManufacturingCardId: e.id,
            showManufacturingCardNode: e,
            showManufacturingCardType: showManufacturingCardNode,
        })

    }

    onSelectionChanged( ) {
		
		let RowsSelected = this.gridApi.getSelectedNodes( );
		
        if ( RowsSelected.length > 0 ) {
			
            this.setState({rowSelected: true});
			
        } else {
			
            this.setState({rowSelected: false});

        }
		
		if( !this.props.opts || ( this.props.opts.type !== 'storage' && this.props.opts.type !== 'galvanisation' ) ){
		
			this.isEnableFinalCalculationButton( );
			this.isEnableExpenseAddButton( );
		
		}
		
    }

    export_to_excel() {
        if (this.props.opts && (this.props.opts.type === 'storage' || this.props.opts.type === 'galvanisation')) {
            this.gridApi.exportDataAsExcel();

        } else {
            this.gridApi.exportDataAsExcel({
                shouldRowBeSkipped: function (params) {
                    if (params.node.allChildrenCount > 0) {
                        return false;
                    } else {
                        return true;
                    }

                },
                processCellCallback: (params) => {
                    const colDef = params.column.getColDef()
                    // try to reuse valueFormatter from the colDef
                    if (colDef.valueFormatter) {
                        const valueFormatterParams = {
                            ...params,
                            data: params.node.data,
                            node: params.node,
                            colDef: params.column.getColDef()
                    };
                        return colDef.valueFormatter(valueFormatterParams);
                    }
                    return params.value;
                },
            });
        }
    }

    onFilterChanged( params ) {
		
		// console.log( "onFilterChanged" );
		
        if( typeof( this.gridApi ) != 'undefined' ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
			
            let componentInstance = statusBarComponent;
			
            if( ( typeof( statusBarComponent ) !== 'undefined' ) && statusBarComponent.getFrameworkComponentInstance) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }

    showInvoiceAdd() {
        var mult_lns = [];
        var ok = true;
        var existing_invoice = false;
        this.gridApi.getSelectedNodes().forEach((e) => {

            if (e.allLeafChildren && e.allLeafChildren[0]) {
                // Commented after change of many invoices for one loading
                // if (e.allLeafChildren[0].data.invoice!=null && e.allLeafChildren[0].data.invoice!='') existing_invoice=true;
                // if (e.allLeafChildren[0].data.invoice_incvat==null || parseFloat(e.allLeafChildren[0].data.invoice_incvat)==0) ok=false;
                mult_lns.push(e.allLeafChildren[0].data);
            }
        });

        if (!ok) {
            alert(this.props.t('Cannot create an invoice. One of selected items has zero price'));
            return false;
        }

        if (existing_invoice) {
            alert(this.props.t('Cannot create an invoice. One of selected items already has assigned invoice'));
            return false;
        }

        this.setState({
            showInvoiceAdd: true,
            now: Date.now(),
            showInvoiceAddRownodes: mult_lns,
        });
    }
	
	showLoadingsPrint() {

        this.setState({
            showLoadingsPrint: true,
            now: Date.now()
        });
    }
	
	showExpensesAdd( ){
		
		this.setState({
            showExpensesAdd: true,
            now: Date.now()
        });
		
	}
	
	showFinalCalculation( ){
		
		/*let AlertedProvised = false;
		
		let SelectedRows = this.gridApi.getSelectedNodes( );
		
		for( let key in SelectedRows ){
			
			let SelectedRowsChildren = SelectedRows[ key ].allLeafChildren;
			
			for( let key2 in SelectedRowsChildren ){
				
				console.log( SelectedRowsChildren[ key2 ].data.id );
				
				if( 
					( SelectedRowsChildren[ key2 ].data.provision !== null ) || ( SelectedRowsChildren[ key2 ].data.provision_paid_datetime != null ) || 
					( SelectedRowsChildren[ key2 ].data.provision_top !== null ) || ( SelectedRowsChildren[ key2 ].data.provision_top_paid_datetime != null )
				){
						
					AlertedProvised = true;
					
					break;
					
				}
				
			}
			
			if( AlertedProvised == true ){
				
				break;
				
			}
			
		}*/
		
		/*if( AlertedProvised == true ){
			
			if( this.state.locale == 'cs' ){
				
				alert( "Provize za jednu z vybraných nakládek již byla zaplacena" );
				
			} else {
				
				alert( "One or more of choosed loadings already provised" );
				
			}
			
		} else {*/
			
			let AlertedManager = false;
			
			let Manager = null;
		
			let TopManager = null;
			
			let SelectedRows = this.gridApi.getSelectedNodes( );
			
			let loadingsSelected = [ ];
			
			for( let key in SelectedRows ){
				
				let SelectedRowsChildren = SelectedRows[ key ].allLeafChildren;
				
				for( let key2 in SelectedRowsChildren ){
					
					// console.log( SelectedRowsChildren[ key2 ].data );
					
					let RowManager = SelectedRowsChildren[ key2 ].data.manager;
					
					let RowTopManager = SelectedRowsChildren[ key2 ].data.regional_manager;
					
					if( Manager == null ){
						
						Manager = RowManager;
						
					} else {
						
						if( RowManager != null ){
					
							if( RowManager != Manager ){
								
								AlertedManager = true;
								
								break;
								
							}
						
						}
						
					}
					
					if( TopManager == null ){
						
						TopManager = RowTopManager;
						
					} else {
						
						if( RowTopManager != null ){
					
							if( RowTopManager != TopManager ){
								
								AlertedManager = true;
								
								break;
								
							}
						
						}
						
					}
					
					let loading = SelectedRowsChildren[ key2 ].data;
					
					loadingsSelected.push( {
						value: loading.id, 
						label: loading.loading_number + " " + loading.company 
					} );
					
				}
				
				
				
				if( AlertedManager == true ){
					
					break;
					
				}
				
			}
			
			if( AlertedManager == true ){
				
				if( this.state.locale == 'cs' ){
				
					alert( "Vybrané nakládky obsahují různé manažery nebo různé nadřízené manažery" );
				
				} else {
				
					alert( "Different managers or regional manager in choosed loadings" );
				
				}
				
			} else {
				
				if( Manager == TopManager ){
					
					TopManager = null;
					
				}
		
				this.setState({
					final_calculation_manager: Manager,
					final_calculation_top_manager: TopManager,
					final_calculation_loadings: loadingsSelected,
					showFinalCalculation: true,
					now: Date.now()
				});
			
			}
		
		//}
		
	}
	
	isEnableFinalCalculationButton( ){
		
		let SelectedRows = this.gridApi.getSelectedNodes( );
		
		let DisabledFinalCalculation = false;
		
		let loadingsSelected = [ ];
		
		if( SelectedRows.length == 0 ){
			
			DisabledFinalCalculation = true;
			
		}
		
		this.setState( {
			isDisabledFinalCalculationButton: DisabledFinalCalculation
		} );
		
	}
	
	isEnableExpenseAddButton( ){
		
		let SelectedRows = this.gridApi.getSelectedNodes( );
		
		// console.log( SelectedRows );
		
		let DisabledExpenseAdd = false;
		
		let Manager = null;
		
		let TopManager = null;
		
		let loadingsSelected = [ ];
		
		if( SelectedRows.length == 0 ){
			
			DisabledExpenseAdd = true;
			
		} else {
		
			for( let key in SelectedRows ){
				
				let SelectedRowsChildren = SelectedRows[ key ].allLeafChildren;
				
				for( let key2 in SelectedRowsChildren ){
					
					// console.log( SelectedRowsChildren[ key2 ].data.id );
					
					if( Manager == null ){
						
						Manager = SelectedRowsChildren[ key2 ].data.manager;
						
					} else {
					
						if( SelectedRowsChildren[ key2 ].data.manager != Manager ){
							
							DisabledExpenseAdd = true;
							
							break;
							
						}
						
					}
					
					let loading = SelectedRowsChildren[ key2 ].data;
					
					loadingsSelected.push( {
						value: loading.id, 
						label: loading.loading_number + " " + loading.company 
					} );
					
				}
				
				if( DisabledExpenseAdd == true ){
					
					break;
					
				}
				
			}
			
			if( this.state.isAdmin == false ){
				
				let my_user = JSON.parse( localStorage.my_user );
			
				let my_user_id = my_user.id;
				
				if( ( Manager == null ) || ( Manager.toString( ) != my_user_id.toString( ) ) ){ //TODO
					
					DisabledExpenseAdd = true;
					
				}
				
			}
		
		}
		
		// console.log( loadingsSelected );
		
		this.setState( {
			isDisabledExpenseAddButton: DisabledExpenseAdd,
			expense_manager: Manager,
			//isDisabledFinalCalculationButton: DisabledFinalCalculation,
			//isDisabledFinalCalculationButton: Disabled,
			//final_calculation_manager: Manager,
			//final_calculation_top_manager: TopManager, //TODO
			loadingsSelected: ( DisabledExpenseAdd == true ) ? null : loadingsSelected
		} );
		
	}

    async invoiceAdd(data) {

        var form_data = new FormData();

        for (var key in data) {
            if (key !== 'files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0] !== 'undefined') form_data.append('file1', data.files[0]);

        HttpService.post(UrlService.apiDomain() + 'api/invoices', form_data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    // console.log('Successfully updated');
                    this.setState({showInvoiceAdd: false});


                    // console.log('Successfully updated');
                    var rownode;
                    res.data.loadings.map((e) => {
                        rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });

                    this.gridApi.refreshClientSideRowModel('aggregate')
                    this.gridApi.redrawRows({rowNodes: [rownode.parent]});


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }
	
	async loadingsPrint(data) {

        let form_data = new FormData( );
		
		let loading_ids = [];
		
		let checks = data;
		
		let SelectedRows = this.gridApi.getSelectedNodes();
		
		// console.log( data, SelectedRows );
		
		for( let key in SelectedRows ){
			
			let SelectedRowsChildren = SelectedRows[ key ].childrenAfterFilter;
			
			for( let key2 in SelectedRowsChildren ){
				
				// console.log( SelectedRowsChildren[ key2 ].data.id );
				
				loading_ids.push( SelectedRowsChildren[ key2 ].data.id );
				
			}
			
			//loading_ids.push( SelectedRows.data.id );
			
		}
		
		let json_data = { ids: loading_ids, checkboxes: checks };
		
		form_data.append( 'json', JSON.stringify( json_data ) ); 
		
		HttpService.postblob(UrlService.apiDomain() + 'api/documents/loadings/print_pdf', form_data)
            .then( response => {
				
				// console.log(response);
				
				if( response.status != 200 ) {
					
                    alert(this.props.t('Chyba při generaci')+'. '+this.props.t( response.statusText ));
					
                    this.setState({ showLoadingsPrint: false});
					
                    return false;

                }

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
				
                const link = document.createElement('a');
				
                link.href = url;

                var filename = 'mass_print.pdf';
				
                link.setAttribute(
                    'download',
                    filename,
                );

                document.body.appendChild( link );

                link.click( );
				
                link.parentNode.removeChild( link );
				
				this.setState({ showLoadingsPrint: false });

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });

    }
	
	dateFilter( ) {
		
		// console.log( "date filter" );
			
		let FilterDate = this.props.year + "-" + this.props.month;
		
		let EndDate = '';
		
		if( this.props.month == '12' ){
			
			EndDate += ( parseInt( this.props.year ) + 1 ).toString( );
			
			EndDate += '-';
			
			EndDate += '01';
			
		} else {
			
			let EndYear = this.props.year;
			
			let EndMonth = ( parseInt( this.props.month ) + 1 ).toString( );
			
			if( EndMonth.length == 1 ){
				
				EndMonth = '0' + EndMonth;
				
			}
			
			EndDate = EndYear + '-' + EndMonth;
			
		}
		
		return {
			loading_stored: {
				filterType: 'date',
				type: 'inRange',
				dateFrom: FilterDate+'-01 00:00:00',
				dateTo: EndDate+'-01 00:00:00'
			}
		};
        
    }
	
	async HandleAddExpense( Data ) {
		
		Data.created_by = JSON.parse( localStorage.my_user ).id;

        HttpService.post( UrlService.apiDomain() + 'api/expenses', Data )
            .then(res => {
				
                if( res.data === null ){
					
					alert( "Chyba připojení k serveru" );
                
				}
				
				if( 
					( res.data.result !== 'success' )
				) {
					
                    alert( "Chyba připojení k serveru\n" + res.data.message );
					
                } else {
					
                    // console.log( 'Successfully updated' );
					
                    this.setState( { showExpensesAdd: false } );

                }

            })

            .catch( ( error ) => {
				
                alert( error );
				
                // console.log( error );
				
            });


    }
	
	async HandleSetProvision( Data ) {
		
		// console.log( Data );

        HttpService.post( UrlService.apiDomain() + 'api/loadings/provision', Data )
            .then( function( res ) {
				
                if( res.data === null ){
					
					alert( "Chyba připojení k serveru" );
					
					//this.setState( { showFinalCalculation: false, final_calculation_manager: null, final_calculation_top_manager: null, final_calculation_loadings: [] } );
					this.setState( { showFinalCalculation: false } );
					
					return;
                
				}
				
				if( 
					( res.data.result !== 'success' )
				) {
					
                    alert( "Chyba připojení k serveru\n" + res.data.message );
					
					//this.setState( { showFinalCalculation: false, final_calculation_manager: null, final_calculation_top_manager: null, final_calculation_loadings: [] } );
					this.setState( { showFinalCalculation: false } );
					
					return;
					
                }
				
				if( 
					( res.data.message !== 'success' )
				) {
					
                    alert( "Chyba připojení k serveru\n" + res.data.message );
					
					//this.setState( { showFinalCalculation: false, final_calculation_manager: null, final_calculation_top_manager: null, final_calculation_loadings: [] } );
					this.setState( { showFinalCalculation: false } );
					
					return;
					
                }
					
				//this.setState( { showFinalCalculation: false, final_calculation_manager: null, final_calculation_top_manager: null, final_calculation_loadings: [] } );
				this.setState( { showFinalCalculation: false } );
					
                // console.log('Successfully updated');
				
				
				let rownodes = [ ];
				
				let rownodes_parents = [ ];
				
				let row_data = this.state.rowdata;
				
				// console.log( row_data );
				
				res.data.rows.map( function( e ){
					
					let rownode = this.gridApi.getRowNode( e.id );
					
					if ( rownode ) {
						
						rownode.setData( e );
						
					}
					
					rownodes.push( rownode );
					
					rownodes_parents.push( rownode.parent );
					
					for( let i in row_data ){
						
						if( row_data[ i ].id.toString() == e.id.toString( ) ){
							
							// console.log( "set row data", i );
							
							row_data[ i ] = e;
							
						}
						
					}
					
					return true;
					
				}.bind( this ) );
				
				this.setState( { rowdata: row_data, loadingsArrayStamp: ( new Date( ) ).getTime( ) } );
				
				//this.gridApi.setRowData( row_data );

				this.gridApi.refreshClientSideRowModel( 'aggregate' );
				
				this.gridApi.redrawRows( { rowNodes: rownodes_parents } );

            }.bind( this ) )

            .catch( function( error ) {
				
                alert( error );
				
                // console.log( error );
				
            }.bind( this ) );

    }

	ArchiveChange( Ev ){
		
		// console.log( Ev );
									
		if ( Ev.target.value === 'Archive') {
			
			this.setState( {
				
				currentArchive: 'Show archive',
				showArchive: 'Show all'
			}, ( ) => this.setFilter( ) );
			
		} else if( Ev.target.value === 'All' ) {

			this.setState( {
				
				currentArchive: 'Show all',
				showArchive: 'Show actual'
				
			}, ( ) => this.setFilter( ) );
			
		} else if ( Ev.target.value === 'Actual') {
			
			this.setState( {
				
				currentArchive: 'Show actual',
				showArchive: 'Show archive'
				
			}, ( ) => this.setFilter( ) );
		}

	}

    render() {
		
		let rowClassRules = null;
		
		if( this.props.fromAccountants == true ){
			
			rowClassRules = {
				'row-color-orange': function( params ) { 
				
					if( !params ){
						
						return false;
						
					}
				
					if( params.node && params.node.allLeafChildren ){

						for( let i in params.node.allLeafChildren ){
							
							if( params.node.allLeafChildren[ i ].data.color_for_accountants == 'orange' ){
								
								return true;
								
							}
							
						}
		
					}
					
					return false;
				
					
				},
				'row-color-white': function( params ) { 
				
					if( !params ){
						
						return true;
						
					}
					
					if( params.node && params.node.allLeafChildren ){

						for( let i in params.node.allLeafChildren ){
							
							if( params.node.allLeafChildren[ i ].data.color_for_accountants == 'white' ){
								
								return true;
								
							}
							
						}
		
					}
					
					return false;
					
				}
			};
			
		} else {
			
			rowClassRules = {
			
				'row-color-green': function( params ) { 
				
					if( !params ){
						
						return false;
						
					}
					
					if( params.data && params.data.color ){
						
						return params.data.color == "green";
						
					} else if( params.node && params.node.allLeafChildren && params.node.allLeafChildren[ 0 ] ){ 
					
						return params.node.allLeafChildren[ 0 ].data.color == "green"; 
						
					} else {
						
						return false;
						
					}
					
				},
				'row-color-red': function( params ) { 
				
					if( !params ){
						
						return false;
						
					}
					
					if( params.data && params.data.color ){
						
						return params.data.color == "red";
						
					} else if( params.node && params.node.allLeafChildren && params.node.allLeafChildren[ 0 ] ){ 
					
						return params.node.allLeafChildren[ 0 ].data.color == "red"; 
						
					} else {
						
						return false;
						
					}
					
				},
				'row-color-orange': function( params ) { 
				
					if( !params ){
						
						return false;
						
					}
					
					if( params.data && params.data.color ){
						
						return params.data.color == "orange";
						
					} else if( params.node && params.node.allLeafChildren && params.node.allLeafChildren[ 0 ] ){ 
					
						return params.node.allLeafChildren[ 0 ].data.color == "orange"; 
						
					} else {
						
						return false;
						
					}
					
				},
				'row-color-white': function( params ) { 
				
					if( !params ){
						
						return false;
						
					}
					
					if( params.data && params.data.color ){
						
						return params.data.color == "white";
						
					} else if( params.node && params.node.allLeafChildren && params.node.allLeafChildren[ 0 ] ){ 
					
						return params.node.allLeafChildren[ 0 ].data.color == "white"; 
						
					} else {
						
						return false;
						
					}
					
				}

			};
			
		}


        var roles = [];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
            roles.push(r.name);
        });

        var that = this;
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        var height = '100%';
        if (this.props.fromtab) height = '450px';
        var from_trip = this.props.from_trip;
        return (
            <>
                <Pusher
                    channel="my-channel"
                    event="my-event"
                    onUpdate={(e) => { //console.log(e);

                        var msg = JSON.parse(e.message);
                        if (msg.action == 'loadings_edit') {
                            var rowNode = this.gridApi.getRowNode(msg.id);
                            if (typeof rowNode != 'undefined' && typeof rowNode.data != 'undefined' && typeof rowNode.data[msg.field] != 'undefined') {
                                if (rowNode.data[msg.field] != msg.value) {
                                    rowNode.data[msg.field] = msg.value;
                                    var params = {
                                        force: true,
                                        suppressFlash: false,
                                        rowNodes: [rowNode],
                                        columns: [msg.field]
                                    };
                                    this.gridApi.refreshCells(params);
                                }
                            }
                        }

                    }}
                />


				{ !this.props.fromSpedition &&
                <div className="buttons-companies">
                    <div className="btn-group" style={{'marginRight': '9px'}}>
                        {(permissions.includes('loadings.create') && this.props.fromtab && !this.props.from_trip && this.props.company_name && this.props.loading_address) &&
                            <button type="button"
                                    className="btn btn-success btn-sm"
                                    onClick={() => this.setState({now: Date.now(), showModal: true})}
                            ><i className="fa fa-plus"></i>
                            </button>
                        }

                        {permissions.includes('loadings.excel') &&
                            <button type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={this.export_to_excel.bind(this)}
                            ><i className="fa  fa-file-excel"></i>
                            </button>
                        }

                        {(!this.props.opts || this.props.opts.type !== 'storage') &&
                            <>
                                {permissions.includes('loadings.spedition') &&
                                    <button type="button"
                                            className="btn btn-primary btn-sm"
                                            disabled={!this.state.rowSelected}
                                            onClick={() => {
                                                var mult_lns = [];

                                                var tara = {};
                                                JSON.parse(JSON.parse(localStorage.getItem('columns_120')).data).forEach((i) => {
                                                    tara['id_' + i.id] = i;
                                                });
                                                this.gridApi.getSelectedNodes().forEach((e) => {
                                                    if (e.allLeafChildren && e.allLeafChildren[0]) {
                                                        // console.log(e);
                                                        var loading_obj = {};
                                                        var loading_qty = 0;
                                                        var product_type = '';
                                                        var loading_container = '';

                                                        loading_obj = e.allLeafChildren[0].data;

                                                        e.allLeafChildren.forEach((i) => {

                                                            loading_qty = parseInt(loading_qty) + parseInt(i.data.loading_qty);
                                                            product_type = product_type + JSON.parse(localStorage.getItem('product_types_' + i.data.product_type))['name_' + locale] + ', ';
                                                            loading_container = loading_container + i.data.material_units_qty + ' ' + tara['id_' + i.data.material_units_description]['name_' + locale] + ', ';
                                                        });

                                                        loading_container = loading_container.slice(0, -2);
                                                        product_type = product_type.slice(0, -2);
                                                        e.allLeafChildren[0].data.s_product_type = product_type;
                                                        e.allLeafChildren[0].data.s_loading_qty = loading_qty;
                                                        e.allLeafChildren[0].data.s_loading_container = loading_container;
                                                        mult_lns.push(e.allLeafChildren[0].data);
                                                    }
                                                });


                                                this.setState({
                                                    showModalSpedition: true,
                                                    showSpeditionLoadings: mult_lns,
                                                });

                                            }}
                                    ><i className="fa  fa-truck"></i>
                                    </button>
                                }

                                <button type="button"
                                        disabled={!this.state.rowSelected}
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            this.showMultiPrint();

                                        }}
                                ><i className="fa  fa-file-pdf"></i>
                                </button>
                            </>
                        }


                    {!this.props.from_trip &&
						/* <>
                        <button 
							type="button" style={{'marginRight': '9px'}}
							className="btn btn-success btn-sm"
							onClick={ ( ) => {
									
								if (this.state.showArchive === 'Show archive') {
									
									this.setState( {
										
										currentArchive: 'Show archive',
										showArchive: 'Show all'
									}, ( ) => this.setFilter( ) );
									
								} else if( this.state.showArchive === 'Show all' ) {

									this.setState( {
										
										currentArchive: 'Show all',
										showArchive: 'Show actual'
										
									}, ( ) => this.setFilter( ) );
									
								} else if (this.state.showArchive === 'Show actual') {
									
									this.setState( {
										
										currentArchive: 'Show actual',
										showArchive: 'Show archive'
										
									}, ( ) => this.setFilter( ) );
								}

							}}
                        >{this.props.t(this.state.showArchive)} <i className="fa fa-archive"></i>
                        </button> */
						
						<select className="form-control" style={{'marginRight': '9px', 'display': 'inline', 'width': 'auto'}} onChange={ (Ev) => { this.ArchiveChange(Ev) }}>
							<option value="All" selected>{ this.props.t("All") }</option>
							<option value="Actual">{ this.props.t("Actual") }</option>
							<option value="Archive">{ this.props.t("Archive") }</option>
						</select>
						//</>
                    }
                    {(permissions.includes('invoices.create') && !this.props.fromtab) &&

                        <button type="button" style={{'marginRight': '9px'}}
                                disabled={!this.state.rowSelected}
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.showInvoiceAdd();

                                }}
                        >{this.props.t('Create invoice')} <i className="fa  fa-file-invoice"></i>
                        </button>
                    }
                    {this.props.fromtab && !this.props.from_trip &&
                        <a style={{color: 'white'}} href="loadings"
                           className="btn btn-primary btn-sm"
                        ><i className="fa fa-external-link-alt"></i>
                        </a>
                    }
					
					{(permissions.includes('loadings.view') && !this.props.fromtab) && 

                        <button type="button" style={{'marginRight': '9px'}}
                                disabled={!this.state.rowSelected}
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.showLoadingsPrint();

                                }}
                        >{this.props.t('Mass printing')} <i className="fa  fa-print"></i>
                        </button>
                    }
					
					{(permissions.includes('loadings.view') && ( this.props.opts && this.props.opts.type !== 'storage' && this.props.opts.type !== 'galvanisation' ) && !this.props.fromtab) && 

                        <button type="button" style={{'marginRight': '9px'}}
                                disabled={this.state.isDisabledExpenseAddButton}
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.showExpensesAdd( );
                                }}
                        >{this.props.t('Add expense')} <i className="fa  fa-file-signature"></i>
                        </button>
                    }
					
					{(permissions.includes('loadings.final_calculation') && ( this.props.opts && this.props.opts.type !== 'storage' && this.props.opts.type !== 'galvanisation' ) && !this.props.fromtab) && 

                        <button type="button"
                                disabled={this.state.isDisabledFinalCalculationButton}
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.showFinalCalculation( );
                                }}
                        >{this.props.t('Final calculation')} <i className="fa  fa-file-signature"></i>
                        </button>
                    }
					
					{(permissions.includes('loadings.delete') && ( this.props.opts && this.props.opts.type === 'galvanisation' ) && !this.props.fromtab) && 

                        <button
							className="btn btn-danger btn-sm"
							onClick={() => this.deleteRows()}
						>
							{this.props.t('Delete')} <i className="fa fa-trash"></i>
						</button>
                    }
					

                
				
					{ this.props.fromSpedition && permissions.includes('loadings.excel') &&
							
							<button type="button"
									className="btn btn-primary btn-sm"
									onClick={this.export_to_excel.bind(this)}
							><i className="fa  fa-file-excel"></i>
							</button>
						
					}
					
					</div>
				
					<div style={{display: 'inline-block', float: 'right', marginRight: '20px'}}>
						<AgGridState 
							model = "loadings"
							api = { this.gridApi }
							api_column = { this.gridColumnApi }
							ready = { this.state.AgGridReady }
						/>
					</div>
					
					<br clear="all"/>
				
				</div>
				}
				
                {this.props.fromtab && !this.props.from_trip &&
                    <Modal
                        title={this.props.t('Add new')}
                        visibility={this.state.showModal}
                        size="lg"
                        onClose={() => this.setState({showModal: !this.state.showModal})}
                    >
                        <LoadingAdd tmp={this.state.now} company_id={this.props.id}
                                    company_name={this.props.company_name}
                                    loading_address={this.props.loading_address}
                                    onAdd={(data) => this.handleAdd(data)}/>
                    </Modal>
                }
				
                <Modal

                    title={this.state.showLoadingCardName}
                    zindex="1051"
                    visibility={this.state.showLoadingCard}
                    size="lg"
                    onClose={() => this.setState({showLoadingCard: !this.state.showLoadingCard})}
                >
                    <LoadingCard type={this.state.showLoadingCardTypeLoading} tab={this.state.showLoadingCardTab}
                                 show={this.state.showLoadingCard} hide_generate={!( JSON.parse( localStorage.my_permissions ).includes( "loadings.print" ) )} tmp={Date.now()}
                                 loading_number={this.state.showLoadingCardId}/>
                </Modal>


                {(this.props.opts && (this.props.opts.type === 'storage' || this.props.opts.type === 'galvanisation')) &&
                    <Modal

                        title={this.state.showManufacturingCardName}
                        zindex="1051"
                        visibility={this.state.showManufacturingCard}
                        size="lg"
                        onClose={() => this.setState({showManufacturingCard: !this.state.showManufacturingCard})}
                    >
                        <ManufacturingCard onAdd={this.addProduction.bind(this)}
                                           type={this.state.showManufacturingCardType}
                                           rownode={this.state.showManufacturingCardNode}
                                           show={this.state.showManufacturingCard} tmp={Date.now()}
                                           id={this.state.showManufacturingCardId}/>
                    </Modal>


                }

                {(permissions.includes('invoices.create') && !this.props.fromtab) &&
                    <Modal
                        title={this.props.t('Add new')}
                        visibility={this.state.showInvoiceAdd}
                        size="lg"
                        onClose={() => this.setState({showInvoiceAdd: !this.state.showInvoiceAdd})}
                    >
                        <InvoiceAdd rownodes={this.state.showInvoiceAddRownodes} type='loadings' fromtab={false}
                                    tmp={this.state.now}
                                    onAdd={(data) => this.invoiceAdd(data)}/>
                    </Modal>
                }
				
				{(permissions.includes('loadings.view') && !this.props.fromtab) &&
                    <Modal
                        title={this.props.t('Mass printing')}
                        visibility={this.state.showLoadingsPrint}
                        size="lg"
                        onClose={() => this.setState({showLoadingsPrint: !this.state.showLoadingsPrint})}
                    >
                        <LoadingsPrint type='loadings' fromtab={false}
                                    tmp={this.state.now} isopen = {this.state.showLoadingsPrint}
                                    onAdd={(data) => this.loadingsPrint(data)}/>
                    </Modal>
                }
				
				{(permissions.includes('loadings.view') && !this.props.fromtab) &&
                    <Modal
                        title={this.props.t('Add expense')}
                        visibility={this.state.showExpensesAdd}
                        size="lg"
                        onClose={() => this.setState({showExpensesAdd: !this.state.showExpensesAdd})}
                    >
                        <ExpenseAdd 
							isAdmin={
								this.state.isAdmin
							}
							loadingsArray={
								this.state.rowdata ? this.state.rowdata : [ ] 
							} 
							manager={
								this.state.expense_manager
							} 
							loadingsSelected={
								this.state.loadingsSelected
							}
                            onAdd={
								(Data) => this.HandleAddExpense( Data )
							}
						/>
                    </Modal>
                }
				
				{(permissions.includes('loadings.final_calculation') && !this.props.fromtab) &&
                    <Modal
                        title={this.props.t('Final calculation')}
                        visibility={this.state.showFinalCalculation}
                        size="xxl"
                        onClose={() => this.setState({showFinalCalculation: !this.state.showFinalCalculation})}
                    >
                        <LoadingsFinalCalculation
							onSet={
								(Data) => this.HandleSetProvision( Data )
							} 
							isAdmin={
								this.state.isAdmin
							}
							manager={
								this.state.final_calculation_manager
							}
							top_manager={
								this.state.final_calculation_top_manager
							}
							loadingsArrayStamp={
								this.state.loadingsArrayStamp
							}
							loadingsArray={
								this.state.rowdata ? this.state.rowdata : [ ] 
							}
							loadingsSelected={
								this.state.final_calculation_loadings
							}
							isopen={
								this.state.showFinalCalculation
							}
						/>
                    </Modal>
                }

                {(permissions.includes('loadings.spedition') && !this.props.fromtab) &&
                    <Modal

                        title={this.props.t('Spedition')}

                        visibility={this.state.showModalSpedition}
                        size="lg"
                        onClose={() => this.setState({showModalSpedition: !this.state.showModalSpedition})}
                    >
                        <SpeditionCard from_trip={this.props.from_trip} multiprint={this.showMultiPrint.bind(this)}
                                       show={this.state.showModalSpedition} tmp={Date.now()}
                                       accept_route={(data) => this.acceptRoute(data)}
                                       loadings={this.state.showSpeditionLoadings}/>
                    </Modal>
                }
                <Modal
                    title={this.props.t('Audit')}

                    visibility={this.state.showAudit}
                    size="lg"
                    onClose={() => this.setState({showAudit: !this.state.showAudit})}
                >
                    <Audit type={"loadings"} tmp={Date.now()} id={this.state.showAuditId} model={"loading"}/>
                </Modal>
                {(!this.props.fromtab && !permissions.includes('loadings.spedition')) &&
                    <Modal
                        title={this.state.showCompanyCardName}

                        visibility={this.state.showCompanyCard}
                        size="lg"
                        onClose={() => this.setState({showCompanyCard: !this.state.showCompanyCard})}
                    >
                        <CompanyCard show={this.state.showCompanyCard} rownode={this.state.showCompanyCardNode}
                                     tmp={Date.now()} id={this.state.showCompanyCardId} model={"company"}/>
                    </Modal>
                }

                <div style={{'height': height, width: '100%'}} id="loadingsGrid" className="ag-theme-alpine">
                    <AgGridReact
                        modules={this.state.modules}
                        rowData={this.state.rowdata}
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onCellValueChanged={this.onCellCValueChanged}
                        onCellClicked={this.onCellClicked}
                        frameworkComponents={this.state.frameworkComponents}
                        components={this.state.components}
                        enableCellChangeFlash={true}
                        rowSelection={'multiple'}
                        popupParent={this.state.popupParent}
                        suppressContextMenu={false}
                        pagination={true}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        paginationPageSize={1000}
                        localeText={this.state.localeText}
                        statusBar={this.state.statusBar}
                        getRowNodeId={this.getRowNodeId}
                        getContextMenuItems={this.getContextMenuItems}
                        enableGroupEdit={true}
                        onColumnVisible={this.onColumnsStateChanged.bind(this)}
                        onDragStopped={this.onColumnsStateChanged.bind(this)}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        suppressAggFuncInHeader={true}
                        suppressRowClickSelection={true}
						rowClassRules={rowClassRules}
                        autoGroupColumnDef={{
                            headerName: this.props.t('Loading number'),
                            cellRenderer: 'agGroupCellRenderer',

                            cellRendererParams: {
                                suppressCount: true, checkbox: function (params) {


                                    if ((!from_trip && roles.includes('Spedice') && params.node.allLeafChildren[0].data.speditor) || from_trip) {
                                        return false;
                                    } else {
                                        return true;
                                    }

                                }
                            }
                        }}
                        suppressChangeDetection={true}
						suppressColumnVirtualisation={true}
						onRowDataChanged ={ ( ) => { this.gridColumnApi.autoSizeAllColumns( false ); } }

                    />
                </div>
            </>
        );
    }
}

Loadings.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Loadings);

