"use strict";


import UIElementAbstractT from "../../../../Interface/UI/Element/UIElementAbstractT.mjs";

import UIComponentAbstractT from "../../../../Interface/UI/Component/_/UIComponentAbstractT.mjs";

import UIComponentBaseConfigT from "../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../../../Type/UI/Style/UIStyleMapT.mjs";



const UIComponentBaseT = class UIComponentBaseT extends UIComponentAbstractT {
	
	#UIElement = null;
	#UIComponentBaseConfig = null;
	
	#Children = null;
	#Events = null;
	#UIStyles = null;
	
	constructor(
		UIElement,
		UIComponentBaseConfig
	){
		
		if( ( UIElement instanceof UIElementAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentBaseConfig instanceof UIComponentBaseConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		super( );
		
		
		this.#UIElement = UIElement;
		
		this.#UIComponentBaseConfig = UIComponentBaseConfig;
		
		
		this.#Children = new Array( );
		
		this.#Events = new Map( );
		
		this.#UIStyles = new UIStyleMapT( [ ] );
		
	}
	
	ChildSet(
		Index,
		Child
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Child instanceof UIComponentAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index === this.#Children.length ){
			
			this.#Children.push( Child );
			
			this.#UIElement.ChildSet( Index, Child.UIElement( ) );
			
			return;
			
		}
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		this.#UIElement.ChildSet( Index, Child.UIElement( ) );
		
		this.#Children[ Index ] = Child;
		
	}
	
	ChildRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		this.#UIElement.ChildRemove( Index );
		
		this.#Children.splice( Index, 1 );
		
	}
	
	ChildGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		

		return this.#Children[ Index ];
		
	}
	
	ChildrenCount( 
	
	){
		
		return this.#Children.length;
		
	}
	
	EventSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Events.has( Key ) === true ){
			
			throw new Error( "Already exists" );
			
		}
		
		
		this.#UIElement.EventSet( Key, Value );
		
		
		this.#Events.set( Key, Value );
		
	}
	
	EventGet(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Events.has( Key ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		return this.#Events.get( Key );
		
	}
	
	EventExists(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Events.has( Key );
		
	}
	
	EventRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Events.has( Key ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		let Value = this.#Events.get( Key );
		
		
		this.#UIElement.EventRemove( Key );
		
		
		this.#Events.delete( Key );
		
	}
	
	EventKeys( ){
		
		return [ ...this.#Events.keys( ) ];
		
	}
	
	UIStyleMapClear( ){
		
		for( 
			let Key of this.#UIStyles.Keys( )
		){
			
			this.#UIElement.UIStyleRemove( Key );
			
			this.#UIStyles.Remove( Key );
			
		}
		
	}
	
	StyleMapSet( 
		UIStyleMap 
	){
		
		// console.log( "UIComponentBaseT.StyleMapSet", UIStyleMap ); //, ( new Error() ).stack );
		
		if( UIStyleMap === null ){
			
			this.#UIStyles = UIStyleMap;
			
		} else {
		
			if( ( UIStyleMap instanceof UIStyleMapT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
			
			for( 
				let Key of UIStyleMap.Keys( )
			){
				
				let UIStyle = UIStyleMap.Get( Key );
				
				let UIStyleKey = UIStyle.KeyGet( );
				
				let UIStyleValue = UIStyle.ValueGet( );
				
				console.log( "UIComponentBaseT.StyleMapSet", UIStyleKey, UIStyleValue );
				
				this.#UIElement.UIStyleSet( UIStyleKey, UIStyleValue );
				
			}
			
			this.#UIStyles = UIStyleMap.Copy( );
		
		}

	}
	
	StyleMapGet( 
		
	){
		
		return this.#UIStyles.Copy( );
		
	}
	
	ClassNameSet(
		ClassName
	){
		
		if( ClassName !== null ){
		
			if( typeof( ClassName ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		this.#UIElement.ClassNameSet( ClassName );
		
	}
	
	ClassNameGet( 
	
	){
	
		return this.#UIElement.ClassNameGet( );
	
	}
	
	IdSet(
		Id
	){
		
		if( Id !== null ){
		
			if( typeof( Id ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		this.#UIElement.IdSet( Id );
		
	}
	
	IdGet( 
	
	){
	
		return this.#UIElement.IdGet( );
	
	}
	
	Focus( ){
	
		this.#UIElement.Focus( );
	
	}
	
	Blur( ){
	
		this.#UIElement.Blur( );
	
	}
	
	InnerTextSet(
		InnerText
	){
		
		if( typeof( InnerText ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		this.#UIElement.InnerTextSet( 
			InnerText
		);
		
	}
	
	InnerTextGet(
		
	){
		
		return this.#UIElement.InnerTextGet( );
		
	}
	
	ConfigSet(
		UIComponentBaseConfig
	){
		
		if( ( UIComponentBaseConfig instanceof UIComponentBaseConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#UIComponentBaseConfig = UIComponentBaseConfig.Copy( );
		
	}
	
	ConfigGet(
	
	){
		
		return this.#UIComponentBaseConfig.Copy( );
		
	}
	
	EditableSet(
		Editable
	){
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		this.#UIElement.EditableSet( Editable );
	
	}
	
	EditableGet(
	
	){
	
		return this.#UIElement.EditableGet( );
	
	}
	
	UIElement( ){
		
		return this.#UIElement;
		
	}
	
	Update(
		
	){
		
		console.log( this.#UIComponentBaseConfig.IdGet( ), this.#UIComponentBaseConfig.ClassNameGet( ), this.#UIComponentBaseConfig.StyleMapGet( ) );
		
		this.IdSet( this.#UIComponentBaseConfig.IdGet( ) );
		
		this.ClassNameSet( this.#UIComponentBaseConfig.ClassNameGet( ) );
		
		this.StyleMapSet( this.#UIComponentBaseConfig.StyleMapGet( ) );
		
	}
	
	Render( ){
		
		return this.#UIElement.Render( );
		
	}
	
	Start( ){
		
	}
	
	Stop( ){
		
	}
				
};


export default UIComponentBaseT;