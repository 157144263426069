import React, { Component } from "react";
import $ from "jquery";

export default class loadingsStatusCount extends Component {
    constructor(props) {
        super(props);

    }

    render() {


        var findata={netto:0, excvat_kc:0};
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');



        this.props.api.forEachNodeAfterFilter((rowNode, index) => {
            var netto=0;
            var excvat_kc=0;
            if (rowNode.data && rowNode.data.id) {

            } else {



                    if (rowNode.allLeafChildren[0].data.loading_stored != null) {
                        netto=0;
                        excvat_kc=0;
                       if (rowNode.allLeafChildren[0].data.net_material_qty!=null) {
                           netto = rowNode.allLeafChildren.reduce((total, child) => total + parseFloat(child.data?.net_material_qty || 0 ), 0);
                       }
                        if (rowNode.allLeafChildren[0].data.invoice_excvat_kc!=null) {
                            excvat_kc=parseFloat(rowNode.allLeafChildren[0].data.invoice_excvat_kc);
                        }
                        findata['netto'] = findata['netto'] + netto;
                        findata['excvat_kc'] = findata['excvat_kc'] + excvat_kc;
                    }

            }

        });


        return (

            <div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
                <span style={{marginRight:'10px'}}>Netto kg: <strong>{findata['netto'].toFixed(1)}</strong></span>
                <span style={{marginRight:'10px'}}>Bez DPH kč: <strong>{findata['excvat_kc'].toFixed(1)}</strong></span>
            </div>
        );
    }
}